/* Bulma overrides */
$body-size: 18px;
$weight-light: 400;
$weight-normal: 400;
$family-sans-serif: "Source Sans Pro", BlinkMacSystemFont, -apple-system, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif;

/* Bulma*/
@import "~bulma/bulma";

/* Global classes */
.emoji {
    font-style: normal;
}


/* Page */
#pageTitle {
    padding-right: 2rem;
}
.card-content {
    min-height: 12rem;
}

/* Footer */
footer .container {
    color: #aaa;
    padding: 2rem 1rem;
    font-style: italic;
}